<template>
  <div id="app-main">
    <app-full-nav v-if="!isMobileView"></app-full-nav>
    <app-mob-nav v-if="isMobileView"></app-mob-nav>
    <router-view
      class="app-content"
      :clientsResponse="clientsResponse"
      @fetch-clients="fetchClientsData"
      @toggle-activation="toggleActiveState"
      @active-status-select="activeStatusSelected"
      @client-type-select="clientTypeSelected"
      @client-pagination-per-page="clientPaginationPerPage = $event"
      @after-enter="afterLoadAnimation"
      v-if="!loadingApi"
    ></router-view>
    <spinner-view :size="70" v-if="loadingApi"></spinner-view>
  </div>
</template>

<script>
import AppFullNav from '@/components/App/Nav/AppFullNav.vue';
import AppMobNav from '@/components/App/Nav/AppMobNav.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'AppMain',
  components: {
    AppFullNav,
    AppMobNav,
  },
  data () {
    return {
      clientsResponse: {},
      clientTypes: [],
      activeStatusSelection: true,
      clientTypeSelection: null,
      clientPaginationPerPage: null,
      fromLoginPage: false,
      transformMap: {
        cln_id: 'id',
        cln_name: 'name',
        cln_description: 'description',
        cln_email: 'email',
        cln_phone: 'phone',
        cln_active: '_isActiveToggle',
      },
      disableOnlyClientEnter: false,
      loadingApi: true,
      intervalSes: null,
      intervalNotif: null,
    };
  },
  computed: {
    ...mapGetters({
      filteredAppNavItems: 'filteredAppNavItems',
    }),
    isTouchDevice () {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    },
  },
  watch: {
    async $route (newValue, oldValue) {
      const isClientRoute = newValue.name === 'ClientTableListAll';
      if (isClientRoute) {
        await this.setClientData({});
        // Only update client if on client page
        this.fetchClientsData();
      }

      const routeExceptions = ['MembershipEdit', 'MembershipAdd'];
      const isFocusFalseOnPhone = newValue.meta.isFocus === false && this.isTouchDevice;

      if (newValue.name !== oldValue.name && !routeExceptions.includes(newValue.name) && !isFocusFalseOnPhone) {
        // Checks page changed, ignores params
        this.focusFirstInput();
      }
    },
    langChangeEventCo () {
      this.fetchClientTypes();
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'LoginPage') {
        vm.checkOpenOutdatedAppModal();
        vm.fromLoginPage = true;
      }
    });
  },
  mounted () {
    this.fetchData();
  },
  beforeDestroy () {
    clearInterval(this.intervalSes);
    clearInterval(this.intervalNotif);
    this.intervalSes = null;
    this.intervalNotif = null;
  },
  methods: {
    ...mapActions({
      getOneClient: 'getOneClient',
      getClients: 'getClients',
      setActiveClient: 'setActiveClient',
      expandSession: 'expandSession',
      logoutApi: 'logoutApi',
      setClientData: 'setClientData',
      getClientTypes: 'getClientTypes',
      patchClient: 'patchClient',
      checkNotificationCount: 'checkNotificationCount',
    }),
    async fetchData () {
      await this.fetchClientTypes();
      await this.fetchClientsData();
    },
    async fetchClientsData () {
      const params = {
        // cln_active: this.$route.query.active || 1,
        clt_id: this.clientTypeSelection,
        cln_active: this.activeStatusSelection,
        search: this.$route.query.search || null,
        page: this.$route.query.page || null,
        perPage: this.$route.query.perPage || this.clientPaginationPerPage || 5,
        // clientPaginationPerPage : not sure why we need this
      };
      try {
        const res = await this.getClients(params);
        const tempData = res.data.data || [];
        let parsedData = tempData.map(responseObj => this.transformApiDataObject(responseObj, this.transformMap, { isKeepClone: false }));
        parsedData = this.addAdditionalData(parsedData);
        this.clientsResponse = { ...res.data, data: parsedData };
        await this.handleClientAfter(parsedData);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
        this.loadingApi = false;
      }
    },
    async fetchClientData (id) {
      try {
        const res = await this.getOneClient({ id });
        const clientData = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        return clientData;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      return null;
    },
    async handleClientAfter (parsedData) {
      const currClientId = parseInt(this.$route.params.client_id, 10);
      if (!this.disableOnlyClientEnter) {
        if (parsedData.length === 1) {
          await this.setEnterClient(parsedData[0]);
        } else if (currClientId) {
          // cln_id > id {transformed}
          const objFound = parsedData.find(data => data.id === currClientId);
          if (objFound) {
            await this.setEnterClient(objFound);
          } else {
            const res = await this.fetchClientData(currClientId);
            await this.setEnterClient(res);
          }
        } else {
          console.log('[No active client] - Only expand ses');
          await this.setupExpandSession();
          await this.setupCheckNotifications();
        }
      } else if (!this.$route.name) {
        console.log('No route');
        this.$router.push({ name: 'ClientTableListAll' });
      }
      this.disableOnlyClientEnter = true;
      this.loadingApi = false;
    },
    addAdditionalData (arrData) {
      return arrData.map(data => {
        const objFound = this.clientTypes.find(ct => ct.clt_id === data.clt_id);
        return {
          ...data,
          clt_name: objFound?.clt_name,
        };
      });
    },
    async fetchClientTypes () {
      try {
        const res = await this.getClientTypes({});
        this.clientTypes = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async setEnterClient (client) {
      try {
        const res = await this.setActiveClient({ cln_id: client.id });
        await this.setupExpandSession();
        await this.setupCheckNotifications();
        await this.setClientData(client);
        const startComps = ['ClientTableListAll'];
        const notSameClientId = parseInt(this.$route.params.client_id, 10) !== client.id;
        if (startComps.includes(this.$route.name) || !this.$route.name) {
          if (this.filteredAppNavItems.length === 1) {
            // For limited users
            this.$router.replace({
              name: this.filteredAppNavItems[0].route,
              params: {
                client_id: client.id,
              },
            });
          } else {
            this.$router.replace({
              name: 'GroupTableListAll',
              params: {
                client_id: client.id,
              },
            });
          }
        } else if (notSameClientId) {
          // If was logged in previously with a different client
          this.$router.replace({
            params: {
              client_id: client.id,
            },
          });
        }
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    activeStatusSelected (evt) {
      this.activeStatusSelection = evt;
      this.resetPageFilter();
      this.fetchClientsData();
    },
    clientTypeSelected (evt) {
      this.clientTypeSelection = evt;
      this.resetPageFilter();
      this.fetchClientsData();
    },
    async setupCheckNotifications () {
      this.intervalNotif = setInterval(() => {
        if (this.checkHasPermission('view-messages') && this.clientData.id) {
          this.checkNotificationCount();
        } else {
          console.log('No client | No perm');
        }
      }, 5000);
    },
    async setupExpandSession () {
      const minutesToCheck = 60000 * 10; // in minutes
      clearInterval(this.intervalSes);
      await this.checkResExpandSes();
      this.intervalSes = setInterval(() => {
        this.intervalSes = null;
        this.checkResExpandSes();
      }, minutesToCheck);
    },
    async checkResExpandSes () {
      const logoutFunc = () => {
        const currComp = this.$route.name;
        if (currComp !== 'LoginPage') {
          try {
            this.logoutApi(localStorage.sid);
            localStorage.clear();
            this.$router.replace({
              name: 'LoginPage',
            });
          } catch (err) {
            this.errorHelperMixin.parsePrintErrorMsg(err);
          }
        }
      };

      try {
        const res = await this.expandSession();
        const params = { sid: null, user: res.data.data };
        this.setupUserDataToStorage(params);
        if (!this.fromLoginPage) {
          await this.updateAppVersionStore(res.data.appFrontVersion);
          this.checkOpenOutdatedAppModal(true);
        }
        this.fromLoginPage = false;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err, false);
        clearInterval(this.intervalSes);
        this.intervalSes = null;
        logoutFunc();
      }
    },
    async toggleActiveState (event) {
      // console.log('toggleActiveState', evt);
      const params = {
        id: event.row.id,
        cln_active: event.evt.checked,
      };
      try {
        const res = await this.patchClient(params);
        this.showSuccessMsg(res.data.message);
        this.fetchClientsData();
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    focusFirstInput () {
      this.$nextTick(() => {
        const el = document.querySelector('.app-content input');
        const isDatePicker = el?.classList.contains('vdatetime-input');
        if (el && !isDatePicker) {
          el.focus();
        }
      });
    },
    afterLoadAnimation () {
      this.focusFirstInput();
    },
  },
};
</script>

<style lang="scss" scoped>
#app-main {
  flex: 1;
  height: 100%;
  background: $background-color-alt;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.custom-table-list {
  font-size: 85%;
}
</style>
